const moment = require('moment');
const DATE_FORMAT = 'DD/MM/YYYY';

const DP_CONFIG = {
  date: {
    format: 'DD/MM/YYYY',
    useCurrent: false,
    showClear: true,
    showClose: true,
    minDate: false,
    maxDate: false,
    locale: 'vi',
    sideBySide: true,
    allowInputToggle: true
  },
  time: {
    format: 'LT',
    useCurrent: true,
    sideBySide: true,
    locale: 'vi',
    allowInputToggle: true
  }
};

const formatDate = (date, format) => {
  return moment(date).format(format || 'YYYY-MM-DD');
};

const getSubtractDate = (subtract = 0, format = DATE_FORMAT) => {
  return moment()
    .subtract(subtract, 'days')
    .format(format);
};

const getAddDate = (add = 0, format = DATE_FORMAT) => {
  return moment()
    .add(add, 'days')
    .format(format);
};

module.exports = {
  formatDate,
  getSubtractDate,
  getAddDate,
  DP_CONFIG
};
